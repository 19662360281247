import React from 'react';

const Privacy = () => {
  return (
    <div className="p-10 text-base md:text-3xl font-Inter">
      <h1 className="text-center text-3xl md:text-6xl font-Inter text-black font-semibold">
        PRIVACY NOTICE
      </h1>
      <br />
      <h2 className="text-lg md:text-3xl font-bold">
        Last updated 17 May 2024
      </h2>
      <br />
      <p>
        This privacy notice for myhighlights.io ("
        <span className="font-bold">we</span>," "
        <span className="font-bold">us</span>," or "
        <span className="font-bold">our</span>"), describes how and why we might
        collect, store, use, and/or share ("
        <span className="font-bold">process</span>") your information when you
        use our services ("<span className="font-bold">Services</span>"), such
        as when you:<span className="font-bold">Questions or concerns?</span>{' '}
        Reading this privacy notice will help you understand your privacy rights
        and choices. If you do not agree with our policies and practices, please
        do not use our Services.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">SUMMARY OF KEY POINTS</h2>
      <br />

      <p className="text-lg md:text-3xl font-bold italic">
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our table of contents below to find
        the section you are looking for.
      </p>
      <br />
      <h2 className="text-lg md:text-3xl font-bold">
        What personal information do we collect?
      </h2>
      <p>
       We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
      </p>
      <br />

      <p>
      <strong>Personal Information from Google Play Primary Account: </strong> <br/>
      When you sign in with Google Play, we collect your email address, account name, and profile picture from your associated Google Play 'Primary Account'. This is considered your Primary Account information per Google's definitions.
      </p>
      <br/>

      <p>
        <span className="font-bold">
          Do we process any sensitive personal information?
        </span> <br/>
        We do not process sensitive personal information.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        Do we receive any information from third parties?
      </h2>
      <p>
        We may receive information from public databases, marketing partners,
        social media platforms, and other outside sources. Learn more about
        information collected from other sources.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        How do we process your information?
      </h2>
      <p>
        We process your information to provide, improve, and administer our
        Services, communicate with you, for security and fraud prevention, and
        to comply with law. We may also process your information for other
        purposes with your consent. We process your information only when we
        have a valid legal reason to do so. Learn more about how we process your
        information.
      </p>
      <br />

      <p>
      <strong>Processing of Google Play Primary Account Information:</strong> <br/>
      1. Your Primary Account email is used to create, identify and manage your user account. <br/>
      2. Your Primary Account name and picture are displayed on your profile and used to represent your identity and personalize your in-app experience.      </p>
      <br/>

      <h2 className="text-lg md:text-3xl font-bold">
        In what situations and with which parties do we share personal
        information?
      </h2>
      <br />

      <p>
        We may share information in specific situations and with specific third
        parties. Learn more about when and with whom we share your personal
        information.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">What are your rights?</h2>
      <p>
        Depending on where you are located geographically, the applicable
        privacy law may mean you have certain rights regarding your personal
        information. Learn more about your privacy rights.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        How do you exercise your rights?{' '}
      </h2>
      <p>
        The easiest way to exercise your rights is by submitting a data subject
        access request, or by contacting us. We will consider and act upon any
        request in accordance with applicable data protection laws.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        Want to learn more about what we do with any information we collect?
      </h2>
      <p>Review the privacy notice in full.</p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">TABLE OF CONTENTS</h2>
      <br />
      <p>WHAT INFORMATION DO WE COLLECT?</p>
      <p>HOW DO WE PROCESS YOUR INFORMATION?</p>
      <p>DATA COLLECTION AND SAFETY</p>
      <p>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
      <p>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>
      <p>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</p>
      <p>HOW LONG DO WE KEEP YOUR INFORMATION?</p>
      <p>DO WE COLLECT INFORMATION FROM MINORS?</p>
      <p>WHAT ARE YOUR PRIVACY RIGHTS?</p>
      <p>CONTROLS FOR DO-NOT-TRACK FEATURES</p>
      <p>DO WE MAKE UPDATES TO THIS NOTICE?</p>
      <p>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
      <p>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        1. WHAT INFORMATION DO WE COLLECT?
      </h2>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        Personal information you disclose to us
      </h2>
      <br />

      <p>
        <span className="font-bold">In Short:</span> We collect personal
        information that you provide to us.
      </p>
      <br />

      <p>
      We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us. <br/>

      Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following: <br/>

      1. Names <br/>
      2. Email addresses
      </p>

      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        Personal Information from Google Play Primary Account:
      </h2>
      <br />

      <p>
        When you sign in or link your Google Play account with our app, we collect certain information from the Google Play "Primary Account" associated with your Google credentials. This includes your email address, account name, and profile picture. This data is considered your "Primary Account" information per Google's definitions.
      </p>
      
      <br/>

      <p>
      <strong>Sensitive Information. </strong> When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information: <br/>
<strong>Payment Data. </strong> We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by Google Play Store and App Store. You may find their privacy notice link(s) here: https://policies.google.com/privacy and https://www.apple.com/legal/privacy/en-ww/. <br/>

<strong>Social Media Login Data.</strong> We may provide you with the option to register with us using your existing social media account details, like your Facebook, X, or other social media account. If you choose to register in this way, we will collect certain profile information about you from the social media provider, as described in the section called “HOW DO WE HANDLE YOUR SOCIAL LOGINS?” below. <br/>

<strong>Application Data.</strong> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission: <br/>

<strong>1. Mobile Device Access.</strong> We may request access or permission to certain features from your mobile device, including your mobile device's storage, camera, and other features. If you wish to change our access or permissions, you may do so in your device’s settings. <br/>
<strong>2. Push Notifications.</strong> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device’s settings. <br/>
This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes. <br/>

All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        Information automatically collected
      </h2>
      <br />

      <p>
        <span className="font-bold">In Short:</span> Some information — such as
        your Internet Protocol (IP) address and/or browser and device
        characteristics — is collected automatically when you visit our
        Services.
      </p>

      <br />

      <p>
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
      </p>

      <br />

      <p>
        Like many businesses, we also collect information through cookies and
        similar technologies.
      </p>

      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        2. HOW DO WE PROCESS YOUR INFORMATION?
      </h2>
      <br />

      <p>
        <span className="font-bold">In Short:</span> We process your information
        to provide, improve, and administer our Services, communicate with you,
        for security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent.
      </p>
      <br />
  
        <p>
            We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
        </p>
        <ul className="list-disc list-inside">
            <li><strong>To facilitate account creation and authentication and otherwise manage user accounts.</strong> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
            <li><strong>To request feedback.</strong> We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
            <li><strong>To protect our Services.</strong> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
            <li><strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual's vital interest, such as to prevent harm.</li>
        </ul>

      <h2 className="text-lg md:text-3xl font-bold">
        Processing of Google Play Primary Account Information:
      </h2>
      <br />
      <p>
      We process the Personal Information from your Google Play Primary Account in the following ways:
      <br/>
      1. Your Primary Account email address is used to create, identify and manage your user account within our app's systems. <br/>
      2. Your Primary Account name and profile picture are displayed on your user profile page and used throughout the app to represent your identity and personalize your in-app experience.      </p>
      <br/>



      <h2 className="text-lg md:text-3xl font-bold">
        3. DATA COLLECTION AND SAFETY
      </h2>
      <br />

      <p>
      <strong className="font-bold">Email and Name Collection:</strong> <br/>
          Our app provides options for users to sign up using their Google or Apple account. If users choose these sign-up options, we collect their email address and name associated with that email from their respective account, but only if they consent to share this information during sign-up. <br/>
          The email address and name collected are used solely to identify the user and create a unique account within our app and the Supabase database that stores this account information. <br/>
          Apart from Supabase, we do not share or transmit users' email addresses or names to any other third-party services, libraries or SDKs. <br />
          Email collection is completely optional. Users can choose to use our app in 'Guest Mode' without providing any email, name or other personal information. All app features remain fully accessible in Guest Mode. <br/>
          <br/>
          <strong className="font-bold">User Data Deletion:</strong> <br/>
          Users have the option to permanently delete their account and associated email, name and other personal data from our systems and the Supabase database at any time. This can be done within the app by going to Settings/Profile and clicking the "Delete Account" button. Alternatively, users can email hi@myhighlights.io to request deletion of their account data.
      </p>

<br/>
      <h3 className="text-l md:text-2xl font-medium">
        When and with whom do we share your personal information?
      </h3>
      <br/>
      <p>
        We may share information in
        specific situations described in this section and/or with the following
        third parties.We may need to share your personal information in the following
        situations:
      </p>
      <br />

      <p>
        <span className="font-bold">Business Transfers.</span> We may share or
        transfer your information in connection with, or during negotiations of,
        any merger, sale of company assets, financing, or acquisition of all or
        a portion of our business to another company.
      </p>
      <br />

      <p>
        <span className="font-bold">Affiliates.</span> We may share your
        information with our affiliates, in which case we will require those
        affiliates to honor this privacy notice. Affiliates include our parent
        company and any subsidiaries, joint venture partners, or other companies
        that we control or that are under common control with us.
      </p>
      <br />

      <p>
        <span className="font-bold">Business Partners.</span> We may share your
        information with our business partners to offer you certain products,
        services, or promotions.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </h2>
      <br />

      <p>
        <span className="font-bold">In Short:</span> We may use cookies and
        other tracking technologies to collect and store your information.
      </p>
      <br />

      <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
      </h2>
      <br />

      <p>
        <span className="font-bold">In Short:</span> If you choose to register
        or log in to our Services using a social media account, we may have
        access to certain information about you.
      </p>
      <br />

      <p>
        Our Services offer you the ability to register and log in using your
        third-party social media account details (like your Google, Apple, Facebook or Twitter
        logins). Where you choose to do this, we will receive certain profile
        information about you from your social media provider. The profile
        information we receive may vary depending on the social media provider
        concerned, but will often include your name, email address, friends
        list, and profile picture, as well as other information you choose to
        make public on such a social media platform.
      </p>
      <br />

      <p>
        We will use the information we receive only for the purposes that are
        described in this privacy notice or that are otherwise made clear to you
        on the relevant Services. Please note that we do not control, and are
        not responsible for, other uses of your personal information by your
        third-party social media provider. We recommend that you review their
        privacy notice to understand how they collect, use, and share your
        personal information, and how you can set your privacy preferences on
        their sites and apps.
      </p>

      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
      </h2>
      <br />

      <p>
        <span className="font-bold">In Short:</span> We may transfer, store, and
        process your information in countries other than your own.
      </p>
      <br />

      <p>
        Our servers are located in. If you are accessing our Services from
        outside, please be aware that your information may be transferred to,
        stored, and processed by us in our facilities and by those third parties
        with whom we may share your personal information (see "WHEN AND WITH
        WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above), in and other
        countries
      </p>
      <br />

      <p>
        If you are a resident in the European Economic Area (EEA), United
        Kingdom (UK), or Switzerland, then these countries may not necessarily
        have data protection laws or other similar laws as comprehensive as
        those in your country. However, we will take all necessary measures to
        protect your personal information in accordance with this privacy notice
        and applicable law.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        7. HOW LONG DO WE KEEP YOUR INFORMATION?
      </h2>
      <br />

      <p>
        <span className="font-bold">In Short:</span> We keep your information
        for as long as necessary to fulfill the purposes outlined in this
        privacy notice unless otherwise required by law.
      </p>
      <br />

      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements).
      </p>

      <br />

      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        8. DO WE COLLECT INFORMATION FROM MINORS?
      </h2>
      <br />

      <p>
        <span className="font-bold">In Short:</span>We do not knowingly collect
        data from or market to children under 18 years of age.
      </p>
      <br />

      <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Services, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent’s use of the Services. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        may have collected from children under age 18, please contact us at
        hi@myhighlights.io
      </p>

      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        9. WHAT ARE YOUR PRIVACY RIGHTS?
      </h2>
      <br />

      <p>
        <span className="font-bold">In Short:</span>You may review, change, or
        terminate your account at any time.
      </p>
      <br />
      <p>
        <span className="font-bold">Withdrawing your consent:</span>If we are
        relying on your consent to process your personal information, which may
        be express and/or implied consent depending on the applicable law, you
        have the right to withdraw your consent at any time. You can withdraw
        your consent at any time by contacting us by using the contact details
        provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
        below.
      </p>
      <br />

      <p>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">Account Information</h2>
      <br />

      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can: Upon your request to
        terminate your account, we will deactivate or delete your account and
        information from our active databases. However, we may retain some
        information in our files to prevent fraud, troubleshoot problems, assist
        with any investigations, enforce our legal terms and/or comply with
        applicable legal requirements.
      </p>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        10. CONTROLS FOR DO-NOT-TRACK FEATURES
      </h2>
      <br />

      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>

      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        11. DO WE MAKE UPDATES TO THIS NOTICE?
      </h2>
      <br />

      <p>
        If you have questions or comments about this notice, you may contact us
        by post at:
      </p>
      <br />

      <a className="underline" href="/#">
        myhighlights.io
      </a>
      <p>Miyapur </p>
      <p>Hyderabad, Telangana 500049 </p>
      <p>India</p>
      <a className="underline block" href="to:hi@myhighlights.io">
        hi@myhighlights.io
      </a>
      <br />

      <h2 className="text-lg md:text-3xl font-bold">
        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h2>
      <br />

      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please fill out and submit a data subject
        access request.
      </p>
      <br />
    </div>
  );
};

export default Privacy;

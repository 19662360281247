import React, { useState } from 'react';

const questions = [
  {
    question: ' What is Kindle Highlights Sync, and how does it work?',
    answer:
      'Kindle Highlights Sync automatically gathers all of your favorite highlights from Kindle books, presenting them in an organized manner within the app. This feature enables easy access and review of your Kindle highlights, enhancing your reading experience.',
  },
  {
    question: 'Is the app free to use, or does it require a subscription?',
    answer:
      'The app offers a 14-day free trial, following which it requires a subscription. You can choose between a $0.99 per month plan or a discounted $9.99 per year plan, providing approximately 17% savings compared to monthly billing.',
  },
  {
    question: 'What export options are available for Highlights and Notes?',
    answer:
      'Highlights can be exported in PDF and markdown formats. Additionally, all highlights text can be copied for pasting into a note-taking platform, offering flexibility in accessing and using your extracted data outside the app.',
  },
  {
    question: 'Can I set and track my Reading Goals using the app?',
    answer:
      'Yes, the app facilitates users in setting and monitoring their reading goals. You can establish personalized reading targets and conveniently track your progress within the app.',
  },
  {
    question: 'How secure is my data within the app?',
    answer:
      "The app ensures data security by providing a guest mode that stores all data locally on the device. Amazon credentials are solely utilized for login purposes, ensuring they aren't stored or shared with the app owner or any third parties. No data is shared with third parties, and it's not utilized for any personalized experiences.",
  },
  {
    question: 'How can I cancel my subscription?',
    answer:
      'To cancel your subscription, you can manage it directly through your App store or GooglePlay store account settings or within the app, ensuring a hassle-free cancellation process.',
  },
];

export const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(-1);

  const handleCollapse = (index: number) => () => {
    if (index === openIndex) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <section className="bg-RedishOrange py-20 px-7 md:px-20">
      <h1 className="text-OrangeYellow text-3xl md:text-5xl font-LeagueSpartan font-medium mb-9">
        Frequently Asked Questions
      </h1>
      <div className="max-w-[780px] w-full flex flex-col gap-4">
        {questions.map((question, index) => {
          const expand = index === openIndex;
          return (
            <div key={index}>
              <div className="flex gap-5 text-base md:text-3xl font-LeagueSpartan">
                <h3
                  className={`text-white hover:text-OrangeYellow flex-1 flex items-center`}
                >
                  {question.question}
                </h3>
                <svg
                  onClick={handleCollapse(index)}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-7 h-7 md:w-14 md:h-14 cursor-pointer transition-all ease-in duration-300 ${
                    expand ? 'rotate-90' : 'rotate-0'
                  }`}
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.9394 12.0001L8.46973 6.53039L9.53039 5.46973L16.0607 12.0001L9.53039 18.5304L8.46973 17.4697L13.9394 12.0001Z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </div>
              <div
                className={`overflow-hidden transition-[max-height] duration-500 ease-in ${
                  expand ? 'max-h-40' : 'max-h-0 h-0'
                }`}
              >
                <p className="text-lg font-LeagueSpartan font-medium py-4">
                  {question.answer}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

import React from 'react';
import { Container } from 'shared/components';

export const Header = () => {
  return (
    <header className="w-full py-12 bg-Floral">
      <Container>
        <div className="flex justify-between" style={{ bottom: '30px', position: 'relative' }}>
          <h2 className="text-Irish text-2xl md:text-3xl font-semibold font-LeagueSpartan">
            myhighlights.io
          </h2>
          <h2 className="text-3xl font-LeagueSpartan text-Irish" style={{ fontSize: '19px' }}>
          <a href="https://medium.com/@myhighlights.io" target="_blank" rel="noopener noreferrer" aria-label="Highlights app medium blog link"> Blog</a>
          </h2>
        </div>
      </Container>
    </header>
  );
};
